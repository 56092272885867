import { defineNuxtRouteMiddleware, useFetch, useNuxtApp } from 'nuxt/app';
import api from '@/api';
import useAppLogger from '@/composables/useAppLogger';
import {useStoreConfig} from "@/stores/app/config";
const API_CLIENT_URL = import.meta.env.VITE_API_CLIENT_URL;

export default defineNuxtRouteMiddleware((to, from) => {
    // const { $setCookie, $getCookie, $deleteCookie } = useNuxtApp();
    // const storeConfig = useStoreConfig();
    // const COOKIE_NAME = '_token';
    //
    // const cookie = $getCookie(COOKIE_NAME);
    //
    // function fetch() {
    //     $fetch(api.developer.check + `?token=${cookie}`, {
    //         method: 'GET',
    //         baseURL: API_CLIENT_URL,
    //     }).then((result) => {
    //         if (!storeConfig.developer.enabled) {
    //             storeConfig.developer.enabled = true;
    //         }
    //         console.log(result);
    //     }).catch((error) => {
    //         if (error.statusCode === 403) {
    //             $deleteCookie(COOKIE_NAME);
    //             //location.reload();
    //         }
    //     });
    // }
    //
    // if (!process.client && cookie !== null && !storeConfig.developer.enabled) {
    //     // fetch();
    // }
    // if (process.client && cookie !== null) {
    //     console.log('_token  :', cookie);
    //     fetch();
    // }
});
